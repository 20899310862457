

.login {
  background-image: url('./assets/fondo.png');
  background-repeat: repeat-x;
  background-position: center bottom ;
  background-size: auto 100px ;
}

.markDownMulti{

  border: 1px solid rgba(34,36,38,.15);
  border-radius: .28571429rem;
  padding: 12px;
}

.four {
 background-color: #B0193F;
}
